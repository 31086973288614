<template>
  <div>
    <app-page-detail page-title="Detail Resume Contract" page-toolbar-title="Detail Resume Contract" :record-not-found="notFound">
      <template #toolbar>
        <action-button :data-detail="data_detail" @refresh-detail="refreshDetail()" @callback-delete="goBack()"></action-button>
      </template>
      <v-row>
        <v-col cols="12" lg="9">
          <v-row>
            <v-col cols="12" md="12">
              <card-expansion title="General" class="mb-3" :loading="loading">
                <v-row>
                  <v-col cols="12" md="6">
                    <app-text-field dense readonly v-model="resume_contract_header.document_no_" placeholder="No." label="No." outlined hide-details class="mb-2"></app-text-field>
                    <app-text-field dense readonly v-model="resume_contract_header.document_date" type="date" placeholder="Doc. Date" label="Doc. Date" outlined hide-details class="mb-2"></app-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <app-text-field dense readonly v-model="resume_contract_header.area_code" placeholder="Area Code" label="Area Code" outlined hide-details class="mb-2"></app-text-field>
                    <app-text-field dense readonly v-model="resume_contract_header.status" placeholder="Status" label="Status" outlined hide-details class="mb-2"></app-text-field>
                  </v-col>
                </v-row>

                <v-divider class="mb-2"></v-divider>

                <v-row>
                  <v-col cols="6" md="6">
                    <app-text-field dense readonly v-model="resume_contract_header.contract_number" placeholder="Nomor Kontrak" label="Nomor Kontrak" outlined hide-details class="mb-2"></app-text-field>
                    <app-text-field dense readonly v-model="resume_contract_header.contract_date" placeholder="Tanggal" label="Tanggal" type="date" outlined hide-details class="mb-2"></app-text-field>
                    <app-text-field dense readonly v-model="resume_contract_header.first_party" placeholder="Pihak Ke Satu" label="Pihak Ke Satu" outlined hide-details class="mb-2"></app-text-field>
                    <app-text-field dense readonly v-model="resume_contract_header.second_party" placeholder="Pihak Ke Dua" label="Pihak Ke Dua" outlined hide-details class="mb-2"></app-text-field>
                    <app-text-field dense readonly v-model="resume_contract_header.scope_of_work" placeholder="Lingkup Pekerjaan" label="Lingkup Pekerjaan" outlined hide-details class="mb-2"></app-text-field>
                  </v-col>
                  <v-col cols="6" md="6">
                    <app-text-field dense readonly v-model="resume_contract_header.customer_name" placeholder="Project Owner" label="Project Owner" outlined hide-details class="mb-2"></app-text-field>
                    <app-text-field dense readonly v-model="resume_contract_header.customer_status" placeholder="Customer Status" label="Customer Status" outlined hide-details class="mb-2"></app-text-field>
                    <app-text-field dense readonly v-model="resume_contract_header.campaign_no_" placeholder="Campaign No." label="Campaign No." outlined hide-details class="mb-2"></app-text-field>
                    <app-text-field dense readonly v-model="resume_contract_header.campaign_description" placeholder="Campaign Description" label="Campaign Description" outlined hide-details class="mb-2"></app-text-field>
                    <app-text-field dense readonly :value="grandTotal" placeholder="Nilai Kontrak" label="Nilai Kontrak" outlined hide-details class="mb-2" type="number" reverse></app-text-field>
                  </v-col>
                </v-row>

                <v-divider class="mb-2"></v-divider>

                <v-row>
                  <v-col cols="12" md="12">
                    <v-textarea dense readonly v-model="resume_contract_header.execution_time" rows="2" placeholder="Waktu Pelaksanaan" label="Waktu Pelaksanaan" type="date" outlined hide-details class="mb-2"></v-textarea>
                    <v-textarea dense readonly v-model="resume_contract_header.delivery_conditions" rows="2" placeholder="Pengiriman" label="Pengiriman" outlined hide-details class="mb-2"></v-textarea>
                    <v-textarea dense readonly v-model="resume_contract_header.payment_terms" rows="2" placeholder="Cara Pembayaran" label="Cara Pembayaran" outlined hide-details class="mb-2"></v-textarea>
                    <v-textarea dense readonly v-model="resume_contract_header.warranty_period" rows="2" placeholder="Masa Garansi/Pemeliharaan" label="Masa Garansi/Pemeliharaan" outlined hide-details class="mb-2"></v-textarea>
                    <v-textarea dense readonly v-model="resume_contract_header.penalties_fines" rows="2" placeholder="Sanksi dan Denda" label="Sanksi dan Denda" outlined hide-details class="mb-2"></v-textarea>
                    <v-textarea dense readonly v-model="resume_contract_header.damage_loss_claims" rows="2" placeholder="Klaim Rusak & Hilang" label="Klaim Rusak & Hilang" outlined hide-details class="mb-2"></v-textarea>
                    <v-textarea dense readonly v-model="resume_contract_header.other_terms" rows="2" placeholder="Others" label="Others" outlined hide-details class="mb-2"></v-textarea>
                  </v-col>
                </v-row>
              </card-expansion>
              <card-expansion title="Detail" class="mb-6" :loading="loading" no-padding>
                <v-row>
                  <v-col>
                    <v-data-table :items="resume_contract_detail" :headers="datatable_detail.headers" :loading="datatable_detail.loading" class="elevation-0">
                      <template v-slot:[`item.quantity`]="{ item }">
                        <span>{{ numberFormat(item.quantity) }}</span>
                      </template>
                      <template v-slot:[`item.unit_price`]="{ item }">
                        <span>{{ numberFormat(item.unit_price) }}</span>
                      </template>
                      <template v-slot:[`item.amount`]="{ item }">
                        <span>{{ numberFormat(item.quantity * item.unit_price) }}</span>
                      </template>
                    </v-data-table>
                    <v-divider class="mb-2"></v-divider>
                    <v-row>
                      <v-col>
                        <div class="d-flex justify-end px-4">
                          <div style="width: 300px">
                            <app-text-field readonly class="mb-2" label="Jumlah" :value="totalAmount" type="number" :decimal-places="2" outlined hide-details dense reverse></app-text-field>
                          </div>
                        </div>
                        <div class="d-flex justify-end px-4">
                          <div style="width: 100px">
                            <app-text-field readonly class="mb-2 mx-1" label="VAT %" v-model="resume_contract_header.vat" type="number" :decimal-places="2" outlined hide-details dense reverse></app-text-field>
                          </div>
                          <div style="width: 300px">
                            <app-text-field readonly class="mb-2" label="VAT Amount" :value="vatValue" type="number" :decimal-places="2" outlined hide-details dense reverse></app-text-field>
                            <app-text-field readonly class="mb-2" label="DPP" :value="dppAmount" type="number" :decimal-places="2" outlined hide-details dense reverse></app-text-field>
                            <app-text-field readonly class="mb-2" label="Nilai Total" :value="grandTotal" type="number" :decimal-places="2" outlined hide-details dense reverse></app-text-field>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </card-expansion>

              <card-expansion title="List Approval" class="mb-3">
                <v-row>
                  <v-col cols="12">
                    <ApprovalList v-bind:data-list="resume_contract_approval"></ApprovalList>
                  </v-col>
                </v-row>
              </card-expansion>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="3">
          <document-info :resume_contract_header="resume_contract_header"></document-info>
          <app-comment :document_id="parseInt(resume_contract_header.id)" :source_document="resume_contract_header.source_document" :document_no_="resume_contract_header.document_no_"></app-comment>
        </v-col>
      </v-row>
    </app-page-detail>
  </div>
</template>

<script>
import ApprovalList from "./components/ApprovalList.vue";
import DocumentInfo from './components/DocumentInfo.vue';
import ActionButton from './components/ActionButton.vue';
// import SetCloseDocument from "./components/SetCloseDocument.vue";

export default {
  components: {
    // CommentList,
    ApprovalList,
    DocumentInfo,
    ActionButton,
    // SetCloseDocument,
  },
  data() {
    return {
      id: null,
      loading: false,
      data_detail: {},
      resume_contract_header: {},
      resume_contract_detail: [],
      resume_contract_approval: [],
      loading_save: false,
      campaign_list: [],
      search_campaign: "",
      loading_search_campaign: false,
      selected_campaign: null,

      datatable_detail: {
        loading: false,
        headers: [
          { text: "Deskripsi Barang", value: "description" },
          { text: "Satuan", value: "uom" },
          { text: "Unit", value: "quantity", cellClass: "text-end" },
          { text: "Harga Satuan (Rp)", value: "unit_price", cellClass: "text-end" },
          { text: "Harga Total (Rp)", value: "amount", cellClass: "text-end" },
        ],
      },
      dialogAddDetail: false,
      dialogAddDetailData: {},
      dialogEditDetail: false,
      dialogEditDetailData: {},
      dialogEditSelectedIndex: null,
      notFound: false,
    };
  },
  computed: {
    dtId() {
      return parseInt(this.$route.query.id);
    },
    vatValue() {
      var total = 0;
      this.resume_contract_detail.forEach((item) => {
        total += item.quantity * item.unit_price;
      });
      return !isNaN(total * (this.resume_contract_header.vat / 100)) ? (total * (this.resume_contract_header.vat / 100)) : 0;
    },
    totalAmount() {
      var total = 0;
      this.resume_contract_detail.forEach((item) => {
        total += item.quantity * item.unit_price;
      });
      return total;
    },
    dppAmount() {
      var total = 0;
      this.resume_contract_detail.forEach((item) => {
        total += item.quantity * item.unit_price;
      });

      if (this.resume_contract_header.vat == 0) {
        return 0;
      }

      const vat1 = 11 / 100;
      const vat2 = this.resume_contract_header.vat / 100;

      const rs = vat2 * ((vat1 / vat2) * total);
      return (!isNaN(rs)) ? rs : 0;
    },
    grandTotal() {
      var total = 0;
      this.resume_contract_detail.forEach((item) => {
        total += item.quantity * item.unit_price;
      });
      // tambahkan pajak
      // total += total * (this.resume_contract_header.vat / 100);

      const vat1 = 11 / 100;
      const vat2 = this.resume_contract_header.vat / 100;

      const rs = vat2 * ((vat1 / vat2) * total);
      return (isNaN(total + rs)) ? 0 : total + rs;
    },
  },
  methods: {
    refreshDetail() {
      this.getDetail(true);
    },

    /**
     * getDetail
     * Method untuk mengambil data detail dokumen
     */
    async getDetail(refresh = false) {
      this.loading = true;
      this.notFound = false;
      this.showLoadingOverlay(true);

      if (refresh == true) this.AxiosStorageRemove("GET", "resumecontract/detail");

      this.showLoadingOverlay(true);

      var config = {
        params: {
          id: this.dtId,
        },
        cacheConfig: true,
      };
      await this.$axios
        .get("resumecontract/detail", config)
        .then((res) => {
          this.data_detail = res.data.data;
          this.resume_contract_header = res.data.data.resume_contract_header;
          this.resume_contract_detail = res.data.data.resume_contract_detail;
          this.resume_contract_approval = res.data.data.resume_contract_approval;

          this.selected_campaign = this.resume_contract_header.campaign_no_;

          this.showLoadingOverlay(false);

          this.loading = false;
          this.notFound = false;
        })
        .catch((error) => {
          console.log("error.response", error.response);
          
          if(error.response.status == 404) {
            this.notFound = true;
          }
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
  },

  beforeMount() {
    if (typeof this.$route.query.id !== "undefined") {
      this.id = parseInt(this.$route.query.id);
    } else {
      this.id = parseInt(this.$route.params.id);
    }
  },

  mounted() {
    this.getDetail(true);
  },
};
</script>
<style scoped>
.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  vertical-align: top;
  padding-top: 5px;
}
</style>
